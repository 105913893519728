import React, { Component }  from 'react';



export default class NoMatchPage extends Component {
    render() {
        return (
            <div className="not-found">
                <h2 className="not-found__title">404 Error</h2>
                <h3 className="not-found__text">Page not found</h3>
            </div>
        )
    }
}