import React, {Component} from 'react';
import { Image, Collapse } from 'react-bootstrap';
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import constants from "../../helpers/constants";
import MediaDescription from './MediaDescription';

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className = 'slick-next slick--slider--arrow' onClick={onClick}><svg width='1em' height='1em' viewBox='0 0 16 16' className='bi bi-chevron-down' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg></div>
    );
  }
  
function SamplePrevArrow(props) {
const { onClick } = props;
return (
    <div className = 'slick-prev slick--slider--arrow' onClick={onClick}>
        <svg width='1em' height='1em' viewBox='0 0 16 16' className='bi bi-chevron-down' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>
    </div>
);
}


class Media extends Component {

    constructor(props) {
        super(props)
        this.state = {
            url: [],
            media: [],
            open: false,
            isLoaded: false,
            openedItemIndex: -1,
            openedItem: null
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        AOS.init({
            once: true
        });

        document.addEventListener('scroll', function (e) {
            AOS.init({
                once: true,
            });
        });

        this.settings = {
            dots: false,
            infinite: true,
            nextArrow: <SampleNextArrow/>,
            prevArrow: <SamplePrevArrow/>,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
            ]
        };
        this.url = `${constants.urls.API}/getMedia`;

        this.openMediaItem = this.openMediaItem.bind( this );
        this.mediaDescriptionRef = React.createRef();
    }

    componentDidMount() {

        fetch(this.url, {
            method: `GET`
        }).then( res => res.json())
        .then( result =>{
            this.setState({
                isLoaded: true,
                media: result.media
            })
        })
        .catch( err =>{
            console.log(err);
        })
    }

    openMediaItem( item, i, e ) {  
        // scroll to description
        if ( !this.state.open || item.id !== this.state.openedItem?.id ) {
            const descriptionRef = this.mediaDescriptionRef;
            setTimeout(function(){ 
                window.scrollTo({ top: descriptionRef.current.offsetTop, behavior: 'smooth' }); 
            }, 50);
        }
        const prevActive = document.querySelector(`.media--slider__slide__info.active`);
        if ( prevActive ) prevActive.classList.remove(`active`);    
        let parent;
        if( e && e.nativeEvent && e.nativeEvent.path.length > 0  ) {           
            parent = ( e.nativeEvent.path[0].classList.contains(`media--slider__slide__info__icon`) ) ? e.nativeEvent.path[1] : e.nativeEvent.path[2];            
        } 

        if ( item && this.state.openedItem ) {
            if ( JSON.stringify( item ) === JSON.stringify( this.state.openedItem ) ){
                if( parent ) parent.classList[ !this.state.open ? 'add' : 'remove'](`active`);
                this.setState({
                    open: !this.state.open,
                    openedItemIndex: i,
                    openedItem: item
                })
            } 
            else {
                this.setState({
                    open: true,
                    openedItemIndex: i,
                    openedItem: item
                })
                if( parent ) parent.classList.add(`active`)
            }
        }
        else if ( item && this.state.openedItem === null ) {
            this.setState({
                open: true,
                openedItemIndex: i,
                openedItem: item
            })
            if( parent ) parent.classList.add(`active`)
        }
        
    }

    render() {
        const settings = this.settings;
        const media = this.state.media.map(( item, i ) => (
            <div key={i}>
                {/* <div  data-toggle="collapse" data-target="#mediaInfo" aria-expanded="false" aria-controls="mediaInfo" className="media--slider__slide"> */}
                <div aria-controls="example-collapse-text"
                     aria-expanded={this.state.open}>
                    <div className="media--slider__slide__info" >
                        <Image src={constants.urls.UPLOAD +item.logo} className="media--slider__slide__info__img"/>
                        <div className="media--slider__slide__info__text slider--text">
                            <div>
                                <h2>{item.title}</h2>
                                <p><span>{item.description}</span></p>
                            </div>
                            <div className="layer"></div>
                        </div>
                        <div onClick={ e => this.openMediaItem( item, i, e ) } 
                            className={`media--slider__slide__info__icon slider--icon ${ ( this.state.open && this.state.openedItemIndex === i) ? `opened-item-cllps-btn` : `` } `}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

        ))

        if (!this.state.isLoaded) {
            return <div className="loader">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        }
        return(
            <div className="media overflow--hidden">
                <div className="container container--medium">
                    <div className="col-md-12 col-sm-12">
                        <div data-aos="fade-right" data-aos-duration="1000">
                            <h1 className=" page--title media__title">{`Tahweel
                            `}<span>News & Media Center</span></h1>
                            <hr className="media__title__line page--title__line" />
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div data-aos="fade-left" data-aos-duration="1000">
                            <Slider {...settings} className="media--slider" >
                                {media}
                            </Slider>
                        </div>
                    </div>
                    <div ref={ this.mediaDescriptionRef } className="col-md-12 col-sm-12">
                        <MediaDescription 
                            isOpen={ this.state.open }
                            openedItem={ this.state.openedItem  }
                            />
                    </div>
                </div>
            </div>
        )
}}

export default Media ;
