import React, { Component }  from 'react';
import { Image } from 'react-bootstrap';
import filmLogo from '../../img/logo/Tahweelfilm.svg';
import applogoEngLogo from '../../img/logo/Tahweelapplogo-Eng.svg';
import argicultureLogo from '../../img/logo/Tahweelargiculture.svg';
import { NavLink } from 'react-router-dom';
import LineIcon from 'react-lineicons';    
import "./../../css/r.css"; 
import validators from '../../providers/validator';
import constants from "../../helpers/constants";
/* eslint-disable jsx-a11y/anchor-is-valid */



export default class Footer extends Component {

    constructor( props ) {
        super( props );
        console.log( props );
        
        this.state = {
            isThereAnEmailFormError: false,
            wrongEmailFormat: false,
            social: []
        }

        this.emailInput = React.createRef();
        this.subscribeForm = React.createRef();
        this.url = `${constants.urls.API}/subscriber`;
        this.social_url = `${constants.urls.API}/getSocial`;

        this.sendContactForm = this.sendContactForm.bind( this );
        this.emailOnInput = this.emailOnInput.bind( this );
    }

    sendContactForm (e) {
        e.preventDefault(); 
        const email = this.emailInput.current.value;
        if ( email === ``) {
            this.setState({
                isThereAnEmailFormError:true
            })           
        }      
        else{
            const isValid = validators.validate( email, {
                email: true
            }).valid;

            if( !isValid ) {
                this.setState({
                    isThereAnEmailFormError:true,
                    wrongEmailFormat: true
                })
            }
            else {
                fetch(this.url,{
                    method: "POST",
                    body: new FormData( this.subscribeForm.current )
                }).then( fetchResponse =>{
                    console.log(`success: `, fetchResponse);
                    this.emailInput.current.value = ``;
                })
                .catch( err =>{
                    console.log(`err: ${err}`);
                })
            }
        }  
    }

    emailOnInput ( e ) {
        if ( this.state.isThereAnEmailFormError ) {
            const newState = {
                isThereAnEmailFormError: false
            };
            if ( this.state.wrongEmailFormat ) {
                newState.wrongEmailFormat = false;
            }
            this.setState( newState );
        }
    }

    componentDidMount() {
     
        Promise.all([
            fetch(this.social_url, {
                method: `GET`
            })
        ]).then( res =>{
            return Promise.all(
                res.map( response => {
                    return response.json()
                })
            )
        }).then( result =>{
            const newState = {}
            result.forEach( ( el,i ) => {
                if( el.success ) {
                    switch (i) {
                        case 0:
                            newState.social = el.social;
                            break;
                    }
                }
            })
            this.setState( newState );
        })
            .catch( err => {
                console.log(err);

                /* scroll animation end */
            })
    }

    componentWillReceiveProps( nextProps ) {
        if( this.props.renderCount !== nextProps.renderCount ) {
            this.setState({
                isThereAnEmailFormError: false,
                wrongEmailFormat: false
            })
        }
    }
 
    render() {
        const social = this.state.social.map(( item, i ) => (
                <a key={i} href={item.link} target="_blank"><LineIcon name={(() => {
                    switch (item.name) {
                        case "Facebook":   return 'facebook-filled';
                        case "Twitter": return 'twitter-original';
                        case "Linkedin":  return 'linkedin-original';
                        default:      return "";
                    }
                })()} /></a>
        ))
        return (
            <div className = "footer">
                <div className="container container--medium">
                    <div className="row footer__logo">
                        <div className="col-md-4 col-xs-12 footer__logo__col">
                            <NavLink to="/products/2"  className="footer__logo__col__link">
                                <Image src={filmLogo} className="about-img" alt="logo"/>
                            </NavLink>
                        </div>
                        <div className="col-md-4 col-xs-12 footer__logo__col">
                            <a href="http://tahweelpipe.com/en/" className="footer__logo__col__link" target="_blank">
                                <Image src={applogoEngLogo} className="about-img" alt="logo"/>
                            </a>
                        </div>
                        <div className="col-md-4 col-xs-12 footer__logo__col">
                            <NavLink to="/products/3" className="footer__logo__col__link">
                                <Image src={argicultureLogo} className="about-img" alt="logo"/>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <div className="container container--max">
                        <div className="row">			
                            <div className="col-xs-12 col-md-4">
                                <div className="footer__left">
                                    <h4 className="footer__left__title">Contact Us</h4>
                                    <p className="footer__left__text">Our professional team is ready to guide you with our latest products, news, and special offers.</p>
                                    <form ref={this.subscribeForm}  className="footer__left__form">
                                        {
                                            this.state.isThereAnEmailFormError ?
                                            <>                                            
                                                <p className="error-message subscribe-email-error-message" > { this.state.wrongEmailFormat ? "Email is not in correct form" : "Email is required"} </p><br />
                                            </>
                                            :
                                            null
                                        }
                                        <input 
                                            ref={this.emailInput} 
                                            onChange={this.emailOnInput}
                                            type="text"
                                            name="email"
                                            className={`footer__left__form__input ${this.state.isThereAnEmailFormError ? "input-error" : ""}`} 
                                            placeholder="Email Address"/>                                        
                                        <button onClick={ this.sendContactForm } className="footer__left__form__submit">Send</button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-3 offset-md-1">
                                <div className="footer__center">
                                    <div className="footer__center__menu">
                                        <ul>                                            
                                            <li><NavLink to="/" exact>Home</NavLink></li>
                                            <li><NavLink to="/about">About Tahweel</NavLink></li>
                                            <li><NavLink to="/products">Products</NavLink></li>
                                            <li><NavLink to="/media">Media Center</NavLink></li>
                                            <li><NavLink to="/contact">Contact Us</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <div className="footer__right">
                                    <div className="footer__right__social--icon">
                                        {social}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="footer__copyright">
                        <div className="container container--max">
                            <div className="row">			
                                <div className="col-xs-12 col-md-6">
                                    <ul className="footer__copyright__menu">
                                        <li><a href="#">Privacy</a></li>
                                        <li><a href="#">FAQ</a></li>
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <p className="footer__copyright__text">{`© 2020 Tahweel Integrated. 
                                    All Rights Reserved.`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }

};