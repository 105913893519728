import React, {Component} from 'react';
import { Image } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import constants from "../../helpers/constants";
import validator from "../../providers/validator";

import styles from "../../css/modules/Contact.module.css";
import {NavLink} from "react-router-dom";

class SubscriptionVerification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isSuccess: false,
        }

        this.subscription_verify_url = `${constants.urls.API}/verifySubscribtionEmail`;
        this.token = props.match.params.token;
    }

    componentDidMount() {
        fetch(`${this.subscription_verify_url}?token=${this.token}`,{
            method: "GET",
        })
            .then(response => response.json())
            .then( data =>{
                const newState = {}
                newState.isLoaded = true;
                newState.isSuccess = data.success;
                this.setState( newState );

                console.log(`success: `, data);
            })
            .catch( err =>{
                const newState = {}
                newState.isLoaded = true;
                newState.isSuccess = false;
                this.setState( newState );

                console.log(`err: ${err}`);
            })
    }

    render() {
        if (!this.state.isLoaded) {
            return <div className="loader">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        }
        return(
            <div className="home--why overflow--hidden">
                <div className="container container--medium">
                    <div className="row">

                {this.state.isSuccess ?
                    <div>Your email is verified. Click here to go to <NavLink to="/">home page</NavLink>
                    </div>
                    :
                    <div>Error occured</div>
                }
                    </div>
                </div>
            </div>
        )
} }

export default SubscriptionVerification ;