import React, {Component} from 'react';
import { Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HomeCarousel from '../global/HomeCarousel'
import WhyTahweelDescription from "./WhyTahweelDescription";
import constants from "../../helpers/constants";
import styles from "../../css/modules/Home.module.css";

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            url: [],
            state: [],
            whyTahweel:[],
            aboutTahweel:[],
            isLoaded: false,
            selectedWhyTahweel: null,
            showSelectedWhyTahweel: true,
            selectedItemIndex: -1
        }

        
        AOS.init({
            once : true
        });

        document.addEventListener('scroll', function(e) {
            AOS.init({
                once : true,
            });
        });

        this.whyTahweelUrl = `${constants.urls.API}/getWhyTahweel`;
        this.sliderUrl = `${constants.urls.API}/getSliderData`;
        this.aboutUrl = `${constants.urls.API}/getAboutUsData`;

        this.selectWhyTahweel = this.selectWhyTahweel.bind( this );
        this.whyRef = React.createRef();
        this.whyContRef = React.createRef();
    }

    componentDidMount() {
        Promise.all([
            fetch(this.whyTahweelUrl, {
                method: `GET`
            }),
            fetch(this.sliderUrl, {
                method: `GET`
            }),
            fetch(this.aboutUrl, {
                method: `GET`
            })
        ]).then( res =>{
            return Promise.all(
                res.map( response => {
                    return response.json()
                })
            )
        }).then( result =>{
            const newState = {}
            result.forEach( ( el,i ) => {
                if( el.success ) {
                    switch (i) {
                        case 0:
                            newState.whyTahweel = el.whyTahweel;
                            break;
                        case 1:
                            newState.sliders = el.sliders;
                            break;
                        case 2:
                            newState.aboutTahweel = el.about;
                            break;
                        default:
                            break;
                    }
                }
            })
            newState.isLoaded = true;
            this.setState( newState );
        })
        .catch( err => {
            // @TODO handle error
            console.log(err);
            /* scroll animation end */
        })
    }

    selectWhyTahweel ( whyTahweelItem, index ) {
        console.log('whyTahweelItem', this.state.selectedWhyTahweel);
        if ( JSON.stringify( whyTahweelItem ) !== JSON.stringify( this.state.selectedWhyTahweel ) ) {
            let scrollTop = this.whyRef.current.offsetTop + this.whyContRef.current.offsetTop + 50;
            setTimeout(function(){ 
                window.scrollTo({ top: scrollTop, behavior: 'smooth' }); 
            }, 50);

        }

        if( whyTahweelItem ) {
            if ( this.state.selectedWhyTahweel && JSON.stringify( whyTahweelItem ) === JSON.stringify( this.state.selectedWhyTahweel ) ) {
                this.setState({
                    selectedWhyTahweel: null,
                    selectedItemIndex: -1
                })
            }
            else {
                this.setState({
                    selectedWhyTahweel: whyTahweelItem,
                    selectedItemIndex: index
                })
            }
        }
        else if( whyTahweelItem ) {
            this.setState({
                selectedWhyTahweel: whyTahweelItem,
                showSelectedWhyTahweel: true,
                selectedItemIndex: index
            })
        }
    }


    render() {
        const fadeClass = (index) =>{
            if(index%2 == 0){
                return "fade-right";
            }else{
                return "fade-left";
            }
        }

        const why_tahweel_ul = this.state.whyTahweel.map((item, i) => (
            <li key={i} className="nav-item" >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className={`nav-link home--why__tab__nav--link ${( this.state.selectedItemIndex === i ) ? `active` : "" }`} data-toggle="tab">
                    <div data-aos={fadeClass(i)} data-aos-duration="1000">
                        <div className={`home--why__tab__nav--link__content link__active`}>
                            <Image src={constants.urls.UPLOAD +item.path} alt="ManufacturingImg"/>
                            <h2> {item.title.indexOf(" ") != -1 ?
                            item.title.substring(0, item.title.indexOf(" "))+`
                            `+item.title.substring(item.title.indexOf(" "), item.title.length)
                            : item.title
                            } </h2>
                            <div onClick={()=>this.selectWhyTahweel(item, i) }
                                className={`home--why__tab__nav--link__content__arrow why__arrow ${( this.state.selectedItemIndex === i ) ? styles.selectedItem : "" }`}>
                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                     className="bi bi-chevron-down" fill="currentColor"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </a>
            </li>

        ));
        
        const about_tahweel = this.state.aboutTahweel.map((item, i) => (
            <div key={i} className="home--about overflow--hidden">
                <div className="container container--medium">
                    <div className="row home--about__content">
                        <div className="col-md-6 col-xs-12">
                            <div data-aos="fade-right" data-aos-duration="1000">
                                <div className="home--about__content__img">
                                    <Image src={item.image}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12 home--about__content__text">
                            <div data-aos="fade-left" data-aos-duration="1000">
                                <h1 className=" home--about__content__text__title"> {item.title.substring(0, item.title.indexOf(" "))} <span> {item.title.substring(item.title.indexOf(" "), item.title.length)}</span></h1>
                                <hr className=" home--about__content__text__line"/>
                                <p className=" home--about__content__text__text">{item.description}</p>

                                <NavLink to="/about" className="home--about__content__text__link">Read
                                    More</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));

        if (!this.state.isLoaded) {
            return <div className="loader">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        }

        return (
            <div className="home">
                <HomeCarousel sliders={this.state.sliders || []} />
                {about_tahweel}
                <div ref={ this.whyContRef } className="home--why overflow--hidden">
                    <div className="container container--medium">
                        <div className="row">
                            <div className="col-md-12 col-xs-12">
                                <h2 className="home--why__title">Why <span> Tahweel</span></h2>
                                <hr className="home--why__line"/>
                                <div className="row">
                                    <ul className="nav nav-tabs home--why__tab">
                                        {why_tahweel_ul}
                                    </ul>
                                    <div className="tab-content1" ref={ this.whyRef }>
                                        {
                                            this.state.selectedWhyTahweel 
                                                ?
                                            <WhyTahweelDescription 
                                                title={this.state.selectedWhyTahweel.title} 
                                                description={this.state.selectedWhyTahweel.description}
                                                canShow={this.state.showSelectedWhyTahweel}
                                            />  
                                                :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* HomeTab was empty div, but in case if there is some reason to keep it 
                i didn't remove it*/}
                {/* <HomeTab/> */}
            </div>
        )

    }
}

export default Home ;