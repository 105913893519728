import React, {Component} from 'react';
import InputMask from 'react-input-mask';
import { Image } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import constants from "../../helpers/constants";
import validator from "../../providers/validator";

import styles from "../../css/modules/Contact.module.css";

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: [],
            state: [],
            isLoaded: false,
            career: [],
            contact: [],

        }
        AOS.init({
            once: true
        });

        document.addEventListener('scroll', function (e) {
            AOS.init({
                once: true,
            });
        });

        this.contactFormRef = React.createRef();
        this.phoneNumberInput = React.createRef();

        this.career_url = `${constants.urls.API}/getCareer`;
        this.contact_url = `${constants.urls.API}/getContactUs`;
        this.job_url = `${constants.urls.API}/job-application`;

        this.validateBeforeSubmit = this.validateBeforeSubmit.bind( this );
        this.contactFormInputChange = this.contactFormInputChange.bind( this );
    }

    componentDidMount() {
        Promise.all([
            fetch(this.career_url, {
                method: `GET`
            }),
            fetch(this.contact_url, {
                method: `GET`
            })
        ]).then( res =>{
            return Promise.all(
                res.map( response => {
                    return response.json()
                })
            )
        }).then( result =>{
            const newState = {}
            result.forEach( ( el,i ) => {
                if( el.success ) {
                    switch (i) {
                        case 0:
                            newState.career = el.career;
                            break;
                        case 1:
                            newState.contact = el.contact_us;
                            break;
                        default:
                            break;
                    }
                }
            })
            newState.isLoaded = true;
            this.setState( newState );
        }).catch( err => {
                console.log(err);
            })
    }

    validateBeforeSubmit( e ) {
        e.preventDefault();
        const form = this.contactFormRef.current;
        const values = {};

        const invalidFG = document.querySelectorAll('.'+styles['invalid-form-group']);
        if ( invalidFG.length > 0 ) {
            invalidFG.forEach( inv => inv.classList.remove(styles['invalid-form-group']) );
        }

        for (const key in form.elements) {
            if( key.toLowerCase() !== key.toUpperCase() && form.elements[ key ] instanceof HTMLElement ) {
                const input = form.elements[key];
                values[key] = input.value;                
            }
        }
        
        const { valid, messages } = validator.validateObject( values, {
            name: { required: true },
            mobile: { required: true },
            email: { required: true, email: true },
            subject: { required: true },
            message: { required: true },
        });

        if ( !valid ) {
            Object.entries( messages )
                .forEach( ( [ key, value ] ) => {
                    if ( form.elements[ key ] instanceof HTMLElement ) {
                        const invalidInput = form.elements[ key ];
                        invalidInput.parentElement.classList.add( styles.errorInput );
                        if( invalidInput.parentElement && invalidInput.parentElement.parentElement ) {
                            invalidInput.parentElement.parentElement.classList.add(styles['invalid-form-group']);
                        }
                        const errorMessage = invalidInput.parentElement.querySelector(`.${styles.errorMessage}`);
                        console.log( errorMessage );
                        if( errorMessage ) {
                            if ( value && value.length >= 1 ){
                                let errMessage = value[0];
                                if ( errMessage === `value is required` ) {
                                    errMessage = `${key} is required`;
                                }
                                errorMessage.innerHTML =errMessage[0].toUpperCase() + errMessage.substring(1) ; 
                            }
                        }
                    }
                 });
        }
        else {
            fetch(this.job_url,{
                method: "POST",
                body: new FormData( form )
            }).then( fetchResponse =>{
                console.log(`success: `, fetchResponse);
                this.contactFormRef.current.reset();
            })
            .catch( err =>{
                console.log(`err: ${err}`);
            })
        }
    }

    contactFormInputChange( e ) {
        if( e.target ) {
            const parent = e.target.parentElement;
            if ( parent && parent.classList.contains( styles.errorInput ) ) {
                parent.classList.remove( styles.errorInput );
            }
        }
    }

    render() {

        const career = this.state.career.map(( item, i ) => (
            <div key={i} className="row">
                <div className="col-xs-12 col-md-6 col-lg-6 contact__info__left">
                    <div data-aos="fade-right" data-aos-duration="1000">
                        <div className="contact__info__left__content">
                            <h1 className=" page--title contact__info__left__content__title">{item.title.substring(0, item.title.indexOf(" "))} <span>{item.title.substring(item.title.indexOf(" "))}</span></h1>
                            <hr className="page--title__line contact__info__left__content__line" />
                            <p className="contact__info__left__content__text">{item.description}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-6 contact__info__right">
                    <div data-aos="fade-left" data-aos-duration="1000">
                        <div className="contact__info__right__content">
                            <Image src={item.image} className="contact__info__right__content__img"/>
                        </div>
                    </div>
                </div>

            </div>

        ))
        const contact = this.state.contact.map(( item, i ) => (
            <div key={i} className="col-xs-12 col-md-6 col-lg-6">
                {/* eslint-disable-next-line react/jsx-no-target-blank*/ }
                <a href="https://goo.gl/maps/pZMPvGqiJWNSm2Di9" target="_blank"
                   className="contact__directory__bottom__link">{item.factory_name}{`
                   `}
                    {item.country}</a>
                <p className="contact__directory__bottom__phone">{item.telephone_number}{`
                `}
                    {item.fax_number}{`
                    `}
                    <span>
                        {item.po_box != null ? `P.O Box: ${item.po_box}` : ''} </span></p>
            </div>
        ))
        if (!this.state.isLoaded) {
            return <div className="loader">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        }
        return(
            <div className="contact overflow--hidden">
                <div className="contact__info">
                    <div className="container container--medium">
                        {career}
                    </div>                
                </div>
                <div className="contact__directory">
                    <div className="container container--medium">                
                        <div className="row">
                            <div className="col-xs-12 col-md-8 col-lg-8 contact__directory__top">
                                <div data-aos="fade-right" data-aos-duration="1000">
                                    <div className="contact__directory__top__content">
                                        <h1 className=" page--title contact__directory__top__content__title">{`Contact
                                        `} <span>Directory</span></h1>
                                        <hr className="page--title__line contact__directory__top__content__line" />
                                        <p className="contact__directory__top__content__text">Tahweel would be pleased to answer your questions, enquiries and takes your suggestions seriously and thanks you for your time and concern.</p>
                                    </div>
                                    <div className="row contact__directory__bottom">
                                        {contact}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>
                <div className="contact__job">
                    <div className="container container--medium">                
                        <div className="row">
                            <div className="col-xs-12 col-md-12 col-lg-12 ">                            
                                <form method="POST" action={this.job_url} ref={this.contactFormRef}  className="contact__job__form">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-6 col-lg-6">
                                            <div data-aos="fade-right" data-aos-duration="1000">
                                                <div className="form-group row">
                                                    <label htmlFor="name" className="col-sm-2 contact__job__form__label col-form-label form-control-plaintext">Name <span>*</span> </label>
                                                    <div className="col-sm-8">
                                                        <p className={styles.errorMessage}></p>
                                                        <input onChange={this.contactFormInputChange} id="name" name="name" type="text"  className="form-control contact__job__form__input" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="job" className="col-sm-2 contact__job__form__label col-form-label form-control-plaintext">Job Title </label>
                                                    <div className="col-sm-8">
                                                        <p className={styles.errorMessage}></p>
                                                        <input onChange={this.contactFormInputChange} id="job" name="job_title" type="text"  className="form-control contact__job__form__input" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="company" className="col-sm-2 contact__job__form__label col-form-label form-control-plaintext">Company</label>
                                                    <div className="col-sm-8">
                                                        <p className={styles.errorMessage}></p>
                                                        <input onChange={this.contactFormInputChange} id="company" name="company" type="text"  className="form-control contact__job__form__input" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="mobile" className="col-sm-2 contact__job__form__label col-form-label form-control-plaintext">Mobile <span>*</span> </label>
                                                    <div className="col-sm-8">
                                                        <p className={styles.errorMessage}></p>
                                                        <InputMask 
                                                            inputRef={this.phoneNumberInput}
                                                            mask="+999-99-99-99-99" maskChar={null} 
                                                            className="form-control contact__job__form__input"
                                                            id="mobile" name="phone" type="tel"
                                                            placeholder="+999-99-99-99-99"
                                                            onChange={this.contactFormInputChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="email" className="col-sm-2 contact__job__form__label col-form-label form-control-plaintext">Email <span>*</span> </label>
                                                    <div className="col-sm-8">
                                                        <p className={styles.errorMessage}></p>
                                                        <input onChange={this.contactFormInputChange} id="email" type="email" name="email" className="form-control contact__job__form__input" />
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-lg-6">
                                            <div data-aos="fade-left" data-aos-duration="1000">                                            
                                                <div className="form-group row">
                                                    <label htmlFor="subject" className="col-sm-2 contact__job__form__label col-form-label form-control-plaintext">Subject <span>*</span> </label>
                                                    <div className="col-sm-8">
                                                        <p className={styles.errorMessage}></p>
                                                        <input onChange={this.contactFormInputChange} id="subject" name="subject" type="text"  className="form-control contact__job__form__input" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="message" className="col-sm-2 contact__job__form__label col-form-label form-control-plaintext">Message <span>*</span> </label>
                                                    <div className="col-sm-8">                                                        
                                                        <p className={styles.errorMessage}></p>
                                                        <textarea onChange={this.contactFormInputChange} name="message" id="message" cols="30" rows="11" className="form-control contact__job__form__input contact__job__form__textarea"></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-8 offset-sm-2 contact__job__form__submit">
                                                        <button onClick={this.validateBeforeSubmit} className="contact__job__form__submit__button">Submit</button>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>                
                </div>
            </div>
        )
} }

export default Contact ;