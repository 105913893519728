import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';
// import './App.css';
// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Container, Row, Col, Button, Badge, Accordion, Card, Dropdown } from 'react-bootstrap';
// import { render } from '@testing-library/react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Products from './components/pages/Products';
import Media from './components/pages/Media';
import Contact from './components/pages/Contact';
import Industrial from './components/pages/Industrial';
import Agriculture from './components/pages/Agriculture';
import Packaging from './components/pages/Packaging';
import SubscriptionVerification from "./components/pages/SubscriptionVerification";
import NoMatchPage from "./components/pages/NoMatchPage";


function App(props) {

  const history = useHistory();
  const [ renderCount, setRenderCount ] = useState( 0 );

  useEffect(() => {
     return history.listen((location) => { 
       setRenderCount( renderCount + 1 );
     }) 
  },[history, renderCount]);

  return (
    <div className="App">
        <div>
          <Header/>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/products/:id" component={Industrial} >
            </Route> 
            <Route path="/products">
              <Products />
            </Route>
            <Route path="/media">
              <Media/>
            </Route>
            <Route path="/contact">
              <Contact/>
            </Route>
            <Route path="/subscriptionVerification/:token" component={SubscriptionVerification} >
            </Route>
            <Route path="/jobApplicationVerification/:token">
              <Contact/>
            </Route>
                     
            {/* <Route path="/industrial/:id" component={Industrial} >
            </Route>             */}
            {/* <Route path="/agriculture">
              <Agriculture/>
            </Route>
            <Route path="/packaging">
              <Packaging/>
            </Route> */}
            <Route path="/" exact component={Home} />
            {/* 404 */}
            <Route>
              <NoMatchPage/>
            </Route>
          </Switch>
          <Footer renderCount={renderCount} />
        </div>
    </div>
  );
}

export default App;
