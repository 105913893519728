import React from 'react';
import { Collapse } from 'react-bootstrap';
import constants from "../../helpers/constants";

export default function MediaDescription( { isOpen, openedItem } ) {
    const { details_title, date, details_description, image } = openedItem || {};
    return (
        <>
            {
                openedItem ?
                    <Collapse in={ isOpen }>
                        <div className="media--slider__bottom__info">
                            <div className="row">
                                <div className="col-md-9">
                                    <h2 className="media--slider__bottom__info__title">
                                        { details_title }
                                    </h2>
                                </div>
                                <div className="col-md-3">
                                    <p  className="media--slider__bottom__info__date">
                                        { date }
                                    </p>
                                </div>
                                <div className="col-md-9">
                                    
                                        <p dangerouslySetInnerHTML={{ __html: details_description }} className="media--slider__bottom__info__text" />
                                    
                                    { image.length>0 &&
                                        <img src={constants.urls.UPLOAD + image[0].image} alt="description image" className="media--slider__bottom__info__image"/>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </Collapse>
                    :
                    null
            }
        </>
    )
}
