import React from 'react';

export default function WhyTahweelDescription( { title, description, canShow } ) {

    return (
        <div className="tab-content">
            <div id="tab" className={"container fade home--why__content " + ( canShow ? `show` : `` ) }><br/>
                <h3>{ title }</h3>
                <p style={{whiteSpace:"break-spaces"}}>{ description }</p>
            </div>
        </div>
    )
};
